<div class="grid-container">
    <div class="modal-body">
        <h2 class="text-center">Notificaciones</h2>
        <div class="row card-header" *ngFor="let notif of notifs; let i = index">
            <div class="col-12">
                <div class="row card ml-2 mr-2">
                    <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <div class="row">
                                    <div class="col-8">
                                        {{ notif.title }}
                                    </div>
                                    <div class="col-4">
                                        <h6 class="text-right"><small>{{timeAgo(notif.created_at)}}</small></h6>
                                    </div>
                                </div>
                                <span *ngIf="isNew(notif)" class="badge badge-danger ml-2">Nuevo</span>
                            </div>
                            <button class="btn btn-extra-small" (click)="toggleBody(i)">
                                <i class="{{ expanded[i] ? 'tf-ion-ios-minus active-red' : 'tf-ion-ios-plus active-green' }}"></i>
                            </button>
                        </div>
                        <div *ngIf="expanded[i]" class="mt-2">
                            {{ notif.body }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-danger btn-extra-small" (click)="close()">
            Cerrar
        </button>
    </div>
</div>
