<footer class="footer">
    <div class="container">
      <div class="row">
            <div class="col-md-6 col-lg-4 col-sm-6 mb-5 mb-lg-0 text-center text-sm-left mr-auto">
              <div class="footer-widget">
                <h4 class="mb-4">{{ ownerDetail.company_name }}</h4>
                      <p class="lead">{{ ownerDetail.slogan }}</p>
              </div>
            </div>
  
            <div class="col-md-6 col-lg-2 col-sm-6 mb-5 mb-lg-0 text-center text-sm-left">
                 <div class="footer-widget">
                <h4 class="mb-4">{{'label.useful-link'|transloco}}</h4>
                <ul class="pl-0 list-unstyled mb-0">
                  <li><a (click)="toAbout()" href="">{{'label.about-us'|transloco}}</a></li>
                  <li><a routerLink="{{PATH_CONTACT}}">{{'label.contact'|transloco}}</a></li>
                </ul>
                    </div>
            </div>
  
            <div class="col-md-6 col-lg-3 col-sm-6 text-center text-sm-left">
                 <div class="footer-widget">
                <h4 class="mb-4">{{'label.contact'|transloco}}</h4>
                <ul class="list-unstyled">
                  <li class="d-lg-flex justify-content-between"><b>{{'label.location'|transloco}}</b>{{' '}}<small> {{ location }}</small></li>
                  <li class="d-lg-flex justify-content-between"><b>{{'label.email'|transloco}}</b>{{' '}}<small>{{ ownerDetail.contact_mail }}</small></li>
                </ul>
  
                <h5 *ngIf="ownerDetail.contact_phone !== ''">{{'label.phone'|transloco}}{{' '}}{{ ownerDetail.contact_phone }}</h5>
              </div>
            </div>
         </div>
    </div>
  </footer>
  
  
  <div class="footer-btm py-4 ">
    <div class="container">
      <div class="row ">
             <div class="col-lg-6">
                 <p class="copyright mb-0 ">{{ appName + ' v' + version }} &copy; {{ anio | date: "yyyy" }} <a href="{{providerWeb}}" class="font-weight-bold ml-1" target="_blank">{{ ciaName }}</a>. Todos los derechos reservados</p>
             </div>
             <div class="col-lg-6">
                  <ul class="list-inline mb-0 footer-btm-links text-lg-right mt-2 mt-lg-0">
                  <li class="list-inline-item"><a routerLink="{{'/'+privacyPolicyPath}}">{{'label.privacy-policy'|transloco}}</a></li>
                  <li class="list-inline-item"><a routerLink="{{'/'+termConditionsPath}}">{{'label.terms-conditions'|transloco}}</a></li>
                  <!--li class="list-inline-item"><a routerLink="{{'/'+termSalesPath}}">{{'label.terms-sales'|transloco}}</a></li-->
                  <li class="list-inline-item"><a routerLink="{{'/'+cookiePolicyPath}}">{{'label.cookie-policy'|transloco}}</a></li>
                </ul>
             </div>
         </div>
    </div>
  </div>