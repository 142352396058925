export const environment = {
  baseUrl: 'https://easypay-dev.softdirex.com',
  production: true,
  appVersion: '1.0.9',
  localeKey: 'NIKqPYrGejeDFCcRC0Y82V',
  appName: 'Easypay',
  appDescription: 'Manage payments associated with your organization',
  appTags: 'payments,pay,web pay,organizations,corporative,empresas,sitios web,website,web,Pago web,pago facil,easy pay easypay,pagofacil',
  footerInfo: 'Softdirex - Innovate your digital tools',
  officeLocation: 'La Serena - Chile',
  contactEmail: 'sales@softdirex.com',
  contactPhone: '+569 9867 2957',
  orgName: 'Softdirex',
  // Direccion web de softdirex
  providerWeb: 'https://softdirex.com/',
  // OWNER_ID Asociado al producto, si está en 0 carga datos de softdirex
  ownerId: 0,
  productId: 4,
  // BEGIN - Owner config default
  dfConfigCompanyName: 'Eeasypay',
  dfConfigSlogan: 'Paga en línea, cómodo y fácil.',
  dfConfigAbout: 'EasyPay lleva las oficinas de pago de tu comunidad a la comodidad de tu hogar, un servicio al alcance de tu mano ya que podrás efectuar pagos desde tu teléfono movil o computador personal.',
  dfConfigContactPhone: '',
  dfConfigContactMail: 'jleiva@softdirex.com',
  dfConfigAddress: 'Address 1234',
  dfConfigCity: 'Santiago',
  dfConfigCountry: 'Chile',
  dfConfigTermsFilename: 'terms.json',
  dfConfigLang: 'es',
  dfConfigDaysValidQuote: 15,
  dfConfigDefaultNote:'',
  dfConfigTaxPercentage: 0,
  // END - Owner config default
  productBackendEndpoint: 'https://api-easypay-dev.softdirex.com/v1',
  coreFrontendEndpoint: 'https://escritorio-dev.softdirex.com/',
  pwdRegex: '^[\x20-\x7E]{8,30}$',
  rutRegex: '\\b[0-9|.]{1,10}\\-[K|k|0-9]',
  passportRegex: '[a-zA-Z0-9-]{6,100}',
  dniRegex: '[a-zA-Z0-9-]{6,100}',
  documentDataRegex: '[a-zA-Z0-9-]{6,100}',
  namesRegex: '[a-zA-Z\u00C0-\u00ff-\' ]{2,90}',
  phonesRegex: '[0-9+() -]{8,35}',
  addressRegex: '[a-zA-Z\u00C0-\u00ff-.@ 0-9]{1,90}',
  rrssRegex: '[a-zA-Z-/:.#@_0-9%=?]{1,90}',
  obsRegex: '[a-zA-Z/%\u00C0-\u00ff-.,\'\n": 0-9]{2,1000}',
  aboutRegex: '[a-zA-Z/¡!()@=?¿%\u00C0-\u00ff-.,\'\n": 0-9]{2,5000}',
  sessionTimeMins: 40,
  recaptcha: {
    siteKey: '6LcSKvEkAAAAAFfhUa9wcIhCFAd_P_yZFwN6lRNj',
  },
  maxIdentifiers: 3,
  firebaseConfig: {
    apiKey: "AIzaSyDzOdpUKRN41Iqutj_5UtJ_6yizLVDiIwk",
    authDomain: "fir-stock-dev.firebaseapp.com",
    projectId: "fir-stock-dev",
    storageBucket: "fir-stock-dev.appspot.com",
    messagingSenderId: "1056947664405",
    appId: "1:1056947664405:web:54016f64d42d9eac72801c"
  },
};
