import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Commons } from '../shared/Commons';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private userLoggedIn = new Subject<boolean>();
  private data = new BehaviorSubject<any>(null);

  constructor() {
    this.userLoggedIn.next(false);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  setData(newData: any) {
    this.data.next(newData);
  }

  setSessionData(key: string, data: any) {
    sessionStorage.setItem(key, Commons.encryptDataLocal(data))
  }

  getSessionData(key: string): any | null {
    const resultString: string | null = sessionStorage.getItem(key)
    if (resultString != null) {
      return Commons.decryptDataLocal(resultString)
    }
    return null
  }

  getData() {
    return this.data.asObservable();
  }
}
