import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { NotifService } from 'src/app/services/firebase/notif.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';

@Component({
  selector: 'app-manage-notifs',
  standalone: true,
  imports: [
    CommonModule,
    NgbPagination,
    FormsModule,
    SharedModule
  ],
  templateUrl: './manage-notifs.component.html',
  styleUrl: './manage-notifs.component.css'
})
export class ManageNotifsComponent {

  notifs: any[] = [];
  cusId: string = ''
  expanded: boolean[] = [];

  constructor(
    public modalRef: MdbModalRef<ManageNotifsComponent>,
    private notifService: NotifService
  ) {
    this.expanded = Array(this.notifs.length).fill(false);
  }

  toggleBody(index: number) {
    let notif = this.notifs[index]
    if (this.isNew(notif)) {
      notif.open_by.push(this.cusId)
      this.notifService.updateNotif(notif)
        .then(() => {
          console.log('Notificación actualizada con éxito');
        })
        .catch(error => {
          console.error('Error al actualizar la notificación:', error);
        });
    }
    this.expanded[index] = !this.expanded[index];
  }

  isNew(notif: any) {
    return !notif.open_by?.includes(this.cusId)
  }

  close(): void {
    this.modalRef.close()
  }

  public timeAgo(dateString: string): string {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true, locale: es });
  }
}
