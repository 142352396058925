import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Commons } from '../shared/Commons';

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {
    myCompanyUrl: string = environment.productBackendEndpoint + '/mycompany'
    configUrl: string = environment.productBackendEndpoint + '/config'

    constructor(private http: HttpClient) { }

    getOwner(identifier:string) {
        // Cargar ownerId id con Commons
        const url = this.myCompanyUrl + '/owner/' + identifier
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        }
        return this.http.get<any>(url, options)
    }

    getOwners(page:number,limit:number,filters:string | null) {
        // Cargar ownerId id con Commons
        let url = this.configUrl + '/public/companies?page=' + page + '&limit=' + limit
        if(filters! && filters != ''){
            url = this.configUrl + '/public/companies?page=' + page + '&limit=' + limit + '&filters=' + filters
        }
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        }
        return this.http.get<any>(url, options)
    }

    deleteSlider(sliderId: any) {
        const url = this.myCompanyUrl + '/config/slider/' + sliderId
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + Commons.sessionCredentials()
            })
        }
        return this.http.delete<any>(url, options)
    }

    deleteFeature(featureId: any) {
        const url = this.myCompanyUrl + '/config/feature/' + featureId
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + Commons.sessionCredentials()
            })
        }
        return this.http.delete<any>(url, options)
    }

    getCategories() {
        const url = this.configUrl + '/categories'
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + Commons.sessionCredentials()
            })
        }
        return this.http.get<any>(url, options)
    }

}
