import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { CustomersService } from 'src/app/services/customers.service';
import { NotifService } from 'src/app/services/firebase/notif.service';
import { LanguageUtilService } from 'src/app/services/language-util.service';
import { SessionService } from 'src/app/services/session.service';
import { Commons } from 'src/app/shared/Commons';
import { AlertModalComponent } from 'src/app/shared/modals/alert-modal/alert-modal.component';
import { SelectLanguageModalComponent } from 'src/app/shared/modals/select-language-modal/select-language-modal.component';
import { environment } from 'src/environments/environment';
import { ManageNotifsComponent } from '../../inventory/Modals/manage-notifs/manage-notifs.component';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  appName = environment.appName

  PATH_MAIN = Commons.PATH_MAIN_LOGGED
  PATH_MY_COMPANY = Commons.PATH_MY_COMPANY
  PATH_MY_PURCHASES = Commons.PATH_MY_PURCHASES
  PATH_MY_LICENCES = Commons.PATH_MY_LICENCES
  PATH_HOME = Commons.PATH_MAIN
  PATH_MY_CUSTOMER = Commons.PATH_MY_CUSTOMER
  PATH_LOGIN = Commons.PATH_LOGIN
  PATH_ADMIN_ACCOUNTS = Commons.PATH_ADMIN_ACCOUNTS
  PATH_ADMIN_CREATE_ACCOUNTS = Commons.PATH_ADMIN_CREATE_ACCOUNTS
  PATH_ADMIN_INVENTORY = Commons.PATH_ADMIN_INVENTORY
  PATH_ADMIN_PUNTOV = Commons.PATH_ADMIN_PUNTOV

  PATH_CONFIG = Commons.PATH_CONFIG_WITH_LIC
  PATH_CLIENTS = Commons.PATH_CLIENTS

  getScreenWidth: any;
  mobileWidth: number = Commons.MOBILE_WIDTH
  sessionIsOpen: boolean = Commons.sessionIsOpen()
  sessionObject: any = Commons.sessionObject()
  alertModal: MdbModalRef<AlertModalComponent> | null = null;
  title = 'label.access-link'
  selLangModal: MdbModalRef<SelectLanguageModalComponent> | null = null;
  sessionRol: string = Commons.sessionRol(Commons.FLOW_ADMIN)
  notifs: any[] = [];
  newNotifs: any[] = [];
  notificationsModal: MdbModalRef<ManageNotifsComponent> | null = null;
  @ViewChild('mainNavbar', { static: true }) mainNavbar!: ElementRef;

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private modalService: MdbModalService,
    private langService: LanguageUtilService,
    private customersService: CustomersService,
    private notifService: NotifService
  ) { }

  ngOnInit(): void {
    this.sessionIsOpen = Commons.sessionIsOpen()
    this.sessionObject = Commons.sessionObject()
    this.sessionRol = Commons.sessionRol(Commons.FLOW_ADMIN)
    this.getScreenWidth = window.innerWidth
    this.notifService.listenToNotifChanges(this.sessionObject.customer.id, (data) => {
      this.notifs = data;
      this.newNotifs = this.notifs.filter(notif => !notif.open_by?.includes(window.btoa(this.sessionObject.customer.id+'')));
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth
  }

  openLanguageModal() {
    this.selLangModal = this.modalService.open(SelectLanguageModalComponent)
    this.selLangModal.onClose.subscribe((lang: any) => {
      if (lang != this.langService.getLangActive()) {
        this.langService.setLanguage(lang)
        window.location.reload();
      }
    });
  }

  closeSession() {
    if (Commons.sessionIsOpen()) {
      this.sessionService.setUserLoggedIn(false);
      Commons.sessionClose()
      this.router.navigate([Commons.PATH_MAIN]).then(() => {
        window.location.reload();
      });
    }
  }

  getCustomerName() {
    let name = 'NO-NAME'
    if (this.sessionObject.customer) {
      name = this.sessionObject.customer.email
      if (this.sessionObject.customer.personal_data) {
        name = this.sessionObject.customer.personal_data.first_name
        if (this.sessionObject.customer.personal_data.last_name) {
          name = name + ' ' + this.sessionObject.customer.personal_data.last_name
        }
      }
    }
    return name
  }

  getCompanyName() {
    let name = 'NO-NAME'
    if (this.sessionObject.customer) {
      if (this.sessionObject.customer.company) {
        name = this.sessionObject.customer.company.name
      }
    }
    return name
  }

  get withoutCompany() {
    return !Commons.validField(Commons.sessionObject().customer.company)
  }

  toSupport() {
    this.openNewWindow(Commons.PATH_SUPPORT)
  }

  toMyCompany() {
    if (this.withoutCompany) {
      // cierra la sesion para volver a cargar la sesion en el login
      this.openNewWindowWithoutCompany(this.PATH_MY_COMPANY)
    } else {
      this.openNewWindow(this.PATH_MY_COMPANY)
    }
  }

  toPurchases() {
    this.openNewWindow(this.PATH_MY_PURCHASES)
  }

  toLicences() {
    this.openNewWindow(this.PATH_MY_LICENCES)
  }

  openNewWindow(path: string) {
    this.customersService.createTransientAuth().subscribe({
      next: (v) => {
        Commons.openWithExternalToken(path, v.transient_auth)
      },
      error: (e) => {
        this.openModal('label.unknown-error', 'label.unknown-error-contact-retry', Commons.ICON_ERROR)
      },
      complete: () => { }
    })

  }

  getPlan() {
    const planCategory = Commons.getPlan()
    switch (planCategory) {
      case Commons.LICENCE_TRIAL:
        return 'label.trial-licence'
      case Commons.LICENCE_BASIC:
        return 'label.basic-licence'
      case Commons.LICENCE_MEDIUM:
        return 'label.medium-licence'
      case Commons.LICENCE_PREMIUM:
        return 'label.premium-licence'
      default:
        return 'label.without-licence'
    }
  }

  openNewWindowWithoutCompany(path: string) {
    this.customersService.createTransientAuth().subscribe({
      next: (v) => {
        Commons.openWithExternalToken(path, v.transient_auth)
        this.closeSession()
      },
      error: (e) => {
        this.openModal('label.unknown-error', 'label.unknown-error-contact-retry', Commons.ICON_ERROR)
      },
      complete: () => { }
    })

  }

  openModalWithRedirection(title: string, message: string, icon: string, path: string) {
    this.alertModal = this.modalService.open(AlertModalComponent, {
      data: { title: title, message: message, icon: icon },
    })
    this.alertModal.onClose.subscribe(() => {
      this.openNewWindow(path)
    });
  }

  openModal(title: string, message: string, icon: string) {
    this.alertModal = this.modalService.open(AlertModalComponent, {
      data: { title: title, message: message, icon: icon },
    })
  }

  isRWorUpper() {
    return this.sessionRol === Commons.USER_ROL_RW || this.sessionRol === Commons.USER_ROL_RWX
  }

  isRWX() {
    return this.sessionRol === Commons.USER_ROL_RWX
  }

  notifications() {
    this.closeNavbar()
    this.notificationsModal = this.modalService.open(ManageNotifsComponent, { data: { notifs: this.notifs, cusId: window.btoa(this.sessionObject.customer.id+'') } },)
  }

  closeNavbar(): void {
    const navbar = this.mainNavbar.nativeElement;
    if (navbar.classList.contains('show')) {
      navbar.classList.remove('show');
    }
  }

}
