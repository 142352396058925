<nav class="navbar navbar-expand-lg navbar-light bg-white w-100 navigation" id="navbar">
  <div class="container">
    <a class="navbar-brand font-weight-bold" routerLink="/">{{ ownerDetail.company_name }}</a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar"
      aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse " id="main-navbar">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item active">
          <a class="nav-link" routerLink="/">{{'label.home'|transloco}}</a>
        </li>

        <li *ngIf="sessionIsOpen" class="nav-item">
          <a class="nav-link" routerLink="{{PATH_ACCOUNTS}}">{{'label.accounts'|transloco}}</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" routerLink="{{PATH_PAY}}">{{'label.pay'|transloco}}</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" (click)="toAbout()" href="">{{'label.about-us'|transloco}}</a>
        </li>

        <!--li class="nav-item">
          <a class="nav-link" href="faqs">FAQ.</a>
        </li-->

        <li class="nav-item">
          <a class="nav-link" routerLink="{{PATH_CONTACT}}">{{'label.contact'|transloco}}</a>
        </li>

        <li *ngIf="getScreenWidth < mobileWidth" class="nav-item">
          <a class="nav-link" (click)="openLanguageModal()">{{'language.title'|transloco}}</a>
        </li>
        <!-- Pages -->
        <li *ngIf="getScreenWidth < mobileWidth" class="nav-item dropdown dropdown-slide">
          <a *ngIf="sessionIsOpen" class="nav-link dropdown-toggle" href="#" id="navbarDropdown4" role="button"
            data-delay="350" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{getCustomerName()}}
          </a>
          <a *ngIf="!sessionIsOpen" class="nav-link dropdown-toggle" href="#" id="navbarDropdown4" role="button"
            data-delay="350" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ 'label.login-invite' | transloco }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown4">
            <li *ngIf="sessionIsOpen"><a routerLink="{{PATH_MY_CUSTOMER}}">{{'label.profile'|transloco}}</a></li>
            <!--li *ngIf="sessionIsOpen"><a href="user/orders">Orders</a></li>
            <li *ngIf="sessionIsOpen"><a href="user/favorites">My favorites</a></li-->
            <li *ngIf="sessionIsOpen"><a routerLink="{{PATH_MAIN}}" class="btn btn-small btn-transparent">{{'label.dashboard'|transloco}}</a></li>
            <li *ngIf="!sessionIsOpen"><a routerLink="{{PATH_LOGIN}}" class="btn btn-small btn-main btn-block">{{'login.title'|transloco}}</a></li>
            <li *ngIf="!sessionIsOpen"><a routerLink="{{PATH_REGISTER}}" class="btn btn-small btn-main btn-block">{{'register.title'|transloco}}</a></li>
            <li *ngIf="sessionIsOpen"><a (click)="closeSession()" class="btn btn-small btn-main btn-block">{{'label.close'|transloco}}</a></li>
          </ul>
        </li><!-- /Pages -->

      </ul>
    </div>
    <!-- Navbar-collapse -->

    <ul class="top-menu list-inline mb-0 d-none d-lg-block" id="top-menu">

      <!--li class="dropdown cart-nav dropdown-slide list-inline-item">
        <a href="#" class="dropdown-toggle cart-icon" data-toggle="dropdown" data-hover="dropdown">
          <i class="tf-ion-android-cart"></i>
        </a>
        <div class="dropdown-menu cart-dropdown">
          <div class="media">
            <a routerLink="/product-single">
              <img class="media-object img- mr-3" src="assets/images/cart-1.jpg" alt="image" />
            </a>
            <div class="media-body">
              <h6>Ladies Bag</h6>
              <div class="cart-price">
                <span>1 x</span>
                <span>1250.00</span>
              </div>
            </div>
            <a href="#" class="remove"><i class="tf-ion-close"></i></a>
          </div>

          <div class="media">
            <a routerLink="/product-single">
              <img class="media-object img-fluid mr-3" src="assets/images/cart-2.jpg" alt="image" />
            </a>
            <div class="media-body">
              <h6>Skinny Jeans</h6>
              <div class="cart-price">
                <span>1 x</span>
                <span>1250.00</span>
              </div>
            </div>
            <a href="#" class="remove"><i class="tf-ion-close"></i></a>
          </div>

          <div class="cart-summary">
            <span class="h6">Total</span>
            <span class="total-price h6">$1799.00</span>

            <div class="text-center cart-buttons mt-3">
              <a routerLink="/cart" class="btn btn-small btn-transparent btn-block">View Cart</a>
              <a routerLink="/checkout" class="btn btn-small btn-main btn-block">Checkout</a>
            </div>
          </div>
        </div>
      </li-->
      <li class="list-inline-item"><a href="#"></a></li>
      <li class="dropdown cart-nav dropdown-slide list-inline-item">
        <a href="#" (click)="openLanguageModal()" class="dropdown-toggle cart-icon" data-toggle="dropdown" data-hover="dropdown">
          <i class="tf-ion-ios-world mr-3"></i>
        </a>
      </li>

      <li class="dropdown cart-nav dropdown-slide list-inline-item">
        <a href="#" class="dropdown-toggle cart-icon" data-toggle="dropdown" data-hover="dropdown">
          <i class="tf-ion-ios-person mr-3"></i>
        </a>
        <div class="dropdown-menu cart-dropdown">
          <li *ngIf="sessionIsOpen">
            <h3>{{getCustomerName()}}</h3>
          </li>
          <li *ngIf="sessionIsOpen"><a routerLink="{{PATH_MY_CUSTOMER}}">{{'label.profile'|transloco}}</a></li>
          <!--li *ngIf="sessionIsOpen"><a href="user/orders">Orders</a></li>
          <li *ngIf="sessionIsOpen"><a href="user/favorites">My favorites</a></li-->
          <li *ngIf="sessionIsOpen"><a routerLink="{{PATH_MAIN}}" class="btn btn-small btn-transparent">{{'label.dashboard'|transloco}}</a></li>
          <div *ngIf="!sessionIsOpen" class="text-center cart-buttons mt-3">
            <a routerLink="{{PATH_LOGIN}}" class="btn btn-small btn-main btn-block">{{'login.title'|transloco}}</a>
          </div>
          <div *ngIf="!sessionIsOpen" class="text-center cart-buttons mt-3">
            <a routerLink="{{PATH_REGISTER}}" class="btn btn-small btn-main btn-block">{{'register.title'|transloco}}</a>
          </div>
          <div *ngIf="sessionIsOpen" class="text-center cart-buttons mt-3">
            <a (click)="closeSession()" class="btn btn-small btn-main btn-block">{{'label.close'|transloco}}</a>
          </div>
        </div>
      </li>
  </ul>
  </div>
</nav>