import { Injectable } from '@angular/core';
import { getFirestore, collection, query, where, onSnapshot, orderBy, doc, updateDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotifService {
  private db = getFirestore(initializeApp(environment.firebaseConfig));

  constructor() {}

  listenToNotifChanges(userId: number, callback: (data: any[]) => void) {
    const notifCollection = collection(this.db, 'Notif');
    let code = window.btoa(userId + '')
    // TODO: agregar paginacion con parametros page y limit
    const q = query(
      notifCollection, 
      where('receptors', 'array-contains', code),
      orderBy('created_at', 'desc')
    );

    onSnapshot(q, (snapshot) => {
      const changes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      callback(changes);
    });
  }

  async updateNotif(notif: any): Promise<void> {
    try {
      const notifRef = doc(this.db, 'Notif', notif.id);

      await updateDoc(notifRef, {
        open_by: notif.open_by
      });
    } catch (error) {
      console.error('Error actualizando la notificación:', error);
      throw new Error('Error actualizando la notificación');
    }
  }
}
