<div *ngIf="showPaginator" class="row justify-content-end">
  <div class="col-lg-4">
    <ul class="list-unstyled mb-4">
      <li class="d-flex justify-content-between pb-2 mb-3">
        <div>
          <li *ngIf="paginator.currentPage == 1" class="page-item disabled">
            <button class="page-link">
              <i class="tf-ion-arrow-left-b"></i>
              <span class="sr-only">First</span>
            </button>
          </li>
          <li *ngIf="paginator.currentPage != 1" class="page-item">
            <button class="page-link" (click)="toPage(1)">
              <i class="tf-ion-arrow-left-b"></i>
              <span class="sr-only">First</span>
            </button>
          </li>
        </div>
        <div>
          <li *ngIf="paginator.currentPage == 1" class="page-item disabled">
            <button class="page-link" tabindex="-1">
              <i class="tf-ion-android-arrow-dropleft"></i>
              <span class="sr-only">Previous</span>
            </button>
          </li>
          <li *ngIf="paginator.currentPage != 1" class="page-item">
            <button class="page-link" (click)="toPage(paginator.page - 1)">
              <i class="tf-ion-android-arrow-dropleft"></i>
              <span class="sr-only">Previous</span>
            </button>
          </li>
        </div>
        <div *ngFor="let pageIndex of paginator.allPages">
          <li *ngIf="pageIndex == paginator.page" class="page-item active">
            <button class="page-link">{{ pageIndex }}</button>
          </li>
          <li *ngIf="pageIndex != paginator.page && pageIndex != '...'" class="page-item">
            <button class="page-link" (click)="toPage(pageIndex)">
              {{ pageIndex }}
            </button>
          </li>
          <li *ngIf="pageIndex != paginator.page && pageIndex == '...'" class="page-item disabled">
            <button class="page-link" (click)="toPage(pageIndex)">
              {{ pageIndex }}
            </button>
          </li>
        </div>
        <div>
          <li *ngIf="paginator.lastPage != paginator.page" class="page-item">
            <button class="page-link" (click)="toPage(paginator.page + 1)">
              <i class="tf-ion-android-arrow-dropright"></i>
              <span class="sr-only">Next</span>
            </button>
          </li>
          <li *ngIf="paginator.lastPage == paginator.page" class="page-item disabled">
            <button class="page-link">
              <i class="tf-ion-android-arrow-dropright"></i>
              <span class="sr-only">Next</span>
            </button>
          </li>
        </div>
        <div>
          <li *ngIf="paginator.currentPage != paginator.lastPage" class="page-item">
            <button class="page-link" (click)="toPage(paginator.lastPage)">
              <i class="tf-ion-arrow-right-b"></i>
              <span class="sr-only">Last</span>
            </button>
          </li>
          <li *ngIf="paginator.currentPage == paginator.lastPage" class="page-item disabled">
            <button class="page-link">
              <i class="tf-ion-arrow-right-b"></i>
              <span class="sr-only">Last</span>
            </button>
          </li>
        </div>
      </li>
      <li *ngIf="showSizes" class="d-flex justify-content-between pb-2 mb-3">
        <div>
          <li class="page-item disabled">
            <div class="page-link"><i class="tf-ion-android-menu"></i></div>
          </li>
        </div>
        <div *ngFor="let element of paginator.currentSizes">
            <li *ngIf="element == paginator.limit" class="page-item active disabled">
              <button class="page-link">
                <b>{{ element }}</b>
              </button>
            </li>
            <li *ngIf="element != paginator.limit" class="page-item">
              <button class="page-link" (click)="changeLimit(element)">
                {{ element }}
              </button>
            </li>
        </div>
      </li>
    </ul>
  </div>
</div>
